import { DeviceParameterConfig } from '@eppendorf/vnls-application-configuration-service-types';
import { DateRangePicker, Switch } from '@eppendorf/vnls-react-components';
import { Device } from '@eppendorf/vnls-telemetry-and-events-types';
import { addDays, addMonths } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useDeviceTypeConfig } from '$components/app-config/useDeviceTypeConfig';
import { ErrorHint } from '$features/monitoring/components/error-hint';
import { ButtonToggle } from '$features/monitoring/history-charts/components/button-toggle/button-toggle';
import { ChartWrapper } from '$features/monitoring/history-charts/history-chart/history-charts-wrapper';
import { useDateRangeChange } from '$features/monitoring/history-charts/useDateRangeChange';
import { useHistoryChartsConfig } from '$features/monitoring/history-charts/useHistoryChartsConfig';
import {
  RANGE_PERIOD,
  useRangePeriod,
} from '$features/monitoring/history-charts/useRangePeriod';
import { useRealtimeDevice } from '$features/monitoring/monitoring.graphql.api';

function DeviceNotConfiguredForHistoryCharts() {
  const { t } = useTranslation();
  return (
    <ErrorHint>
      <ErrorHint.IconHint name="charts" />
      <ErrorHint.Title>
        {t('monitoringDetail.historyChartsDetail.deviceNotConfigured')}
      </ErrorHint.Title>
    </ErrorHint>
  );
}

function findSubject(device: Device | undefined, parameter: DeviceParameterConfig) {
  const subject =
    device?.realtime?.find(
      (realTimeData) =>
        (realTimeData?.subject === parameter.subject ||
          realTimeData?.name === parameter.name) &&
        realTimeData?.dataType === 'telemetry',
    )?.subject ?? parameter.subject;
  return subject;
}

export function MonitoringHistoryCharts() {
  const { t } = useTranslation();
  const [ searchParams ] = useSearchParams();
  const { showThresholdRefs, toggleShowThresholdRefs } = useHistoryChartsConfig();
  const { rangePeriod, rangePeriodItems, from, to } = useRangePeriod();
  const { onRangeChange } = useDateRangeChange();

  const deviceManufacturer = searchParams.get('manufacturer')!;
  const deviceSerialNumber = searchParams.get('serialNumber')!;

  const { data: realtimeDataDevice } = useRealtimeDevice(
    deviceManufacturer,
    deviceSerialNumber,
  );

  const deviceTypeConfig = useDeviceTypeConfig(realtimeDataDevice);
  const showHistoryCharts =
    rangePeriod === RANGE_PERIOD.CUSTOM || (rangePeriod && from && to);

  const telemetryFrequency: number | undefined = deviceTypeConfig?.operational?.minTelemetryFrequency;

  return (
    <div className="p-top-xxl overflow-hidden overflow-y-auto">
      {deviceTypeConfig?.parameters?.length ? (
        <>
          <div className="flex flex__jc--center">
            <ButtonToggle items={rangePeriodItems} />
          </div>
          {rangePeriod === RANGE_PERIOD.CUSTOM && (
            <div className="m-bottom-xxl flex flex__dir--column">
              <DateRangePicker
                dateRange={{
                  from: from ? new Date(from) : undefined,
                  to: to ? new Date(to) : undefined,
                }}
                onDateRangeChange={onRangeChange}
                initialFocus
                defaultMonth={addMonths(new Date(), -1)}
                toDate={new Date()}
                fromDate={addDays(new Date(), -89)}
                className="m-bottom-m"
              />
            </div>
          )}
          <div className="m-bottom-m">
            <small className="font-size-s ">
              {t('monitoringDetail.historyChartsDetail.timezoneHint')}
            </small>
          </div>
          <div className="flex flex__dir--row flex__jc--flex-end gap-s m-x-m m-y-l">
            <span>
              {t('monitoringDetail.historyChartsDetail.showAlertThresholdsToggleLabel')}
            </span>
            <Switch
              checked={showThresholdRefs}
              onCheckedChange={(state) => toggleShowThresholdRefs(state)}
            />
          </div>
          {showHistoryCharts &&
            deviceTypeConfig?.parameters?.map((param) => (
              <ChartWrapper
                key={param.subject}
                parameterConfig={param}
                subject={findSubject(realtimeDataDevice, param)}
                manufacturer={deviceManufacturer}
                serialNumber={deviceSerialNumber}
                rangePeriod={rangePeriod}
                from={from}
                to={to}
                minBucketStep={telemetryFrequency ?? null}
              />
            ))}
        </>
      ) : (
        <DeviceNotConfiguredForHistoryCharts />
      )}
    </div>
  );
}
