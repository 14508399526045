import { DeviceModels } from '@eppendorf/vnls-application-configuration-service-types';
import { Device, RealtimeData } from '@eppendorf/vnls-telemetry-and-events-types';

import { useDeviceTypeConfig } from '$components/app-config/useDeviceTypeConfig';
import { getEnabledRealtimeData } from '$features/monitoring/parameters/utils/getEnabledRealtimeData';

const SECONDARY_PARAMETERS = new Set(['WaterLevel', 'CO2Tank']);

function formatTelemetryData(
  acc: { primaryParameters: RealtimeData[]; secondaryParameters: RealtimeData[] },
  item: RealtimeData,
) {
  if (SECONDARY_PARAMETERS.has(item.subject)) {
    acc.secondaryParameters.push(item);
  } else {
    acc.primaryParameters.push(item);
  }
  return acc;
}

export function useDeviceTelemetryData(device: Device | null | undefined): {
  primaryParameters: RealtimeData[];
  secondaryParameters: RealtimeData[];
} {
  const deviceTypeConfig = useDeviceTypeConfig(device);

  if (!device?.realtime) {
    return { primaryParameters: [], secondaryParameters: [] };
  }

  const enabledRealtimeData = getEnabledRealtimeData(device, deviceTypeConfig);

  if (deviceTypeConfig?.parameters?.length) {
    const deviceParameters = [
      ...deviceTypeConfig.parameters,
      ...(deviceTypeConfig.custom?.[device?.model as DeviceModels]?.parameters ?? []),
    ];
    const deviceTelemetryData = deviceParameters.map(
      ({ subject, name: nameFromDeviceConfig }) => {
        const realtimeData = enabledRealtimeData.find(
          (realtime) =>
            realtime &&
            (realtime.subject === subject || realtime.name === nameFromDeviceConfig) &&
            realtime.dataType === 'telemetry' &&
            realtime.subcomponent.indexOf('#') <= 0,
        );

        if (!realtimeData) {
          return null;
        }

        if (nameFromDeviceConfig) {
          realtimeData.name = nameFromDeviceConfig;
        }

        return realtimeData;
      },
    );

    return deviceTelemetryData
      .filter((data): data is RealtimeData => Boolean(data))
      .reduce(formatTelemetryData, { primaryParameters: [], secondaryParameters: [] });
  }

  return [
    ...(enabledRealtimeData.reduce((map, realtimeData) => {
      if (
        realtimeData?.dataType === 'telemetry' &&
        realtimeData.subcomponent.indexOf('#') <= 0 &&
        !map.has(realtimeData?.subject)
      ) {
        map.set(realtimeData?.subject, realtimeData);
      }
      return map;
    }, new Map<string, RealtimeData>()) || []),
  ]
    .map(([_, realtimeData]) => realtimeData)
    .reduce(formatTelemetryData, { primaryParameters: [], secondaryParameters: [] });
}
