import {
  DeviceModels,
  DeviceTypeConfig,
  IncubatorModels,
} from '@eppendorf/vnls-application-configuration-service-types';
import { Device, RealtimeData } from '@eppendorf/vnls-telemetry-and-events-types';
import i18next from 'i18next';

function findBySubject(device: Device, subject: string) {
  return device.realtime.find(
    (realtime) =>
      realtime?.subject === subject &&
      realtime?.dataType === 'status' &&
      realtime?.subcomponent === 'enabled' &&
      realtime?.value === 'true',
  );
}

function overwriteRealtimeDataValue(
  item: RealtimeData,
  key: string,
  defaultKey?: string,
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- needed to dynamically create i18next key
  const value = i18next.t(key as any, { defaultValue: defaultKey });

  if (key !== value) {
    // eslint-disable-next-line no-param-reassign -- desired mutation
    item.value = value;
  }
}

function setWaterLevelValue(realtimeData: (RealtimeData | null)[]) {
  const waterLevel = realtimeData.find(
    (realtime) =>
      realtime &&
      realtime.subject === 'WaterLevel' &&
      realtime.dataType === 'telemetry' &&
      realtime.subcomponent.indexOf('#') <= 0,
  );

  if (waterLevel) {
    overwriteRealtimeDataValue(
      waterLevel,
      `monitoringDetail.overwrite.waterLevel.${waterLevel.value}`,
    );
  }
}

function addCO2TankIfEnabled(
  realtimeData: (RealtimeData | null)[],
  config: DeviceTypeConfig<DeviceModels> | null,
  deviceModel: IncubatorModels,
) {
  // eslint-disable-next-line security/detect-object-injection -- no user input here
  const co2Tank = config?.custom?.[deviceModel]?.parameters?.find(
    (p) => p.subject === 'CO2Tank',
  );

  if (co2Tank) {
    const co2Concentration = realtimeData.find(
      (realtime) =>
        realtime?.subject === 'CO2Concentration' &&
        realtime?.dataType === 'status' &&
        realtime?.subcomponent === 'state',
    );

    const co2TankRealtimeData = {
      name: co2Tank.name,
      subject: co2Tank.subject,
      timestamp: co2Concentration?.timestamp ?? Date.now(),
      dataType: 'telemetry',
      subcomponent: 'state',
      value: '',
    } as RealtimeData;

    overwriteRealtimeDataValue(
      co2TankRealtimeData,
      `monitoringDetail.overwrite.co2Tank.${co2Concentration?.value}`,
      i18next.t('monitoringDetail.overwrite.co2Tank.default'),
    );

    realtimeData.push(co2TankRealtimeData);
  }
}

export function getEnabledRealtimeData(
  device: Device,
  config: DeviceTypeConfig<DeviceModels> | null,
) {
  const isWaterLevelEnabled = findBySubject(device, 'WaterLevel');
  const isRelativeHumidityEnabled = findBySubject(device, 'RelativeHumidity');

  const enabledRealtimeData = device.realtime.filter((realtime) => {
    if (
      (realtime?.subject === 'WaterLevel' && !isWaterLevelEnabled) ||
      (realtime?.subject === 'RelativeHumidity' && !isRelativeHumidityEnabled)
    ) {
      return false;
    }

    return true;
  });

  setWaterLevelValue(enabledRealtimeData);
  addCO2TankIfEnabled(enabledRealtimeData, config, device?.model as IncubatorModels);

  return enabledRealtimeData;
}
