import {
  Device,
  DevicesGetResponse,
  DevicePathParams,
  DevicesQueryParams,
} from '@eppendorf/vnls-inventory-service-types';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { fetcher } from '$shared/fetch';
import {
  createDevicePath,
  createPathWithQueryParams,
  DEVICES_BASE_PATH,
  objectToURLSearchParams,
  SENSE_DEVICES_BASE_PATH,
} from '$shared/utils/api-paths';

import {
  AssociatedRouterQueryResult,
  OperationalStatus,
  SenseDevice,
} from '$features/devices/devices.types';

export const devicesQueryKey = 'devices';
export const addedDeviceQueryKey = 'addedDevice';
export const deletedDeviceQueryKey = 'deletedDevice';
export const associationListQueryKey = 'associationList';

export const createDevicesQueryWithQueryParams = (queryParams: DevicesQueryParams) => ({
  queryKey: [devicesQueryKey, ...objectToURLSearchParams(queryParams).values()],
  queryFn: async () =>
    fetcher.get<DevicesGetResponse>(
      createPathWithQueryParams(DEVICES_BASE_PATH, queryParams),
    ),
  keepPreviousData: true,
});

export const useDevicesWithQueryParams = (queryParams: DevicesQueryParams) =>
  useQuery(createDevicesQueryWithQueryParams(queryParams));

export const createDeviceQuery = ({
  manufacturer,
  serialNumber,
  enabled = true,
}: Pick<Device, 'manufacturer' | 'serialNumber'> & { enabled?: boolean }) => ({
  queryKey: [devicesQueryKey, manufacturer, serialNumber],
  queryFn: async () => fetcher.get<Device>(createDevicePath(manufacturer, serialNumber)),
  enabled: !!enabled,
});

export const useGetDevice = (
  deviceSearch: Pick<Device, 'manufacturer' | 'serialNumber'>,
) => useQuery(createDeviceQuery(deviceSearch));

export const useAddedDevices = () =>
  useQuery({
    queryKey: [addedDeviceQueryKey],
    queryFn: () => [],
  });

export const useAddDevice = () => {
  const addDevice = (device: DevicePathParams) =>
    fetcher.post<DevicePathParams, Device>(
      `${DEVICES_BASE_PATH}/actions/register`,
      device,
    );

  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<Device>, AxiosError, DevicePathParams>(addDevice, {
    // This is sufficient for now. When we tackle performance we can utilise
    // optimistic updates or saving another single device request by mutating data instead
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([devicesQueryKey]);

      queryClient.setQueryData([addedDeviceQueryKey], variables.serialNumber);
    },
  });
};

export const useReregisterDevice = () => {
  const addDevice = (device: DevicePathParams) =>
    fetcher.post<DevicePathParams, Device>(
      `${DEVICES_BASE_PATH}/actions/reregister`,
      device,
    );

  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<Device>, AxiosError, DevicePathParams>(addDevice, {
    // This is sufficient for now. When we tackle performance we can utilise
    // optimistic updates or saving another single device request by mutating data instead
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([devicesQueryKey]);

      queryClient.setQueryData([addedDeviceQueryKey], variables.serialNumber);
    },
  });
};

export const useUpdateDevice = () => {
  const updateDevice = (device: Device) =>
    fetcher.patch<Device, Device>(
      createDevicePath(device.manufacturer, device.serialNumber),
      device,
    );

  const queryClient = useQueryClient();
  return useMutation<Device, Error, Device>(updateDevice, {
    onSuccess: () => {
      queryClient.invalidateQueries([devicesQueryKey]);
    },
  });
};

export const useDeleteDevice = () => {
  const deleteDevice = (device: DevicePathParams) =>
    fetcher.post<DevicePathParams, never>(
      `${DEVICES_BASE_PATH}/actions/deregister`,
      device,
      {
        headers: {
          'x-api-key': 'Easterly-nitrogen-involute-deride',
        },
      },
    );

  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<Device>, AxiosError, DevicePathParams>(deleteDevice, {
    // This is sufficient for now. When we tackle performance we can utilise
    // optimistic updates or saving another single device request by mutating data instead
    onSuccess: () => {
      queryClient.removeQueries([devicesQueryKey]);
    },
  });
};

export const useGetSenseDevice = (deviceId: string, deviceType: 'gateways' | 'monitor') =>
  useQuery({
    queryKey: ['sense-monitor', deviceId],
    queryFn: async () =>
      fetcher.get<SenseDevice>(`${SENSE_DEVICES_BASE_PATH}/${deviceType}/${deviceId}`),
  });

export const useGetMonitorAssociations = (monitorId: string) =>
  useQuery({
    queryKey: ['sense-gateway', monitorId],
    queryFn: async () =>
      fetcher.get<AssociatedRouterQueryResult[]>(
        `${SENSE_DEVICES_BASE_PATH}/monitors/${monitorId}/associated-gateways`,
      ),
  });

export const useGetOperationalStatus = (monitorId: string, enabled: boolean) =>
  useQuery({
    enabled,
    queryKey: ['sense-battery', monitorId],
    queryFn: async () =>
      fetcher.get<OperationalStatus>(
        `${SENSE_DEVICES_BASE_PATH}/monitors/${monitorId}/ops-status`,
      ),
  });

export type BlockMonitorParams = {
  monitorId: string;
  isBlocked: boolean;
};

export type UpdateAssociationListResponse = {
  blocked: string[];
  serialNumber: string;
  tenantId: string;
  type: string;
};

export type AssociationList = {
  tenantId: string;
  serialNumber: string;
  type: string;
  blocked: Array<string>;
};

export const useGetGatewayAssociationList = (gatewaySerialnumber: string) =>
  useQuery({
    queryKey: [associationListQueryKey, gatewaySerialnumber],
    queryFn: async () =>
      fetcher.get<AssociationList>(
        `${SENSE_DEVICES_BASE_PATH}/gateways/${gatewaySerialnumber}/association-list`,
      ),
  });

export const useUpdateMonitorBlockedState = (gatewaySerialNumber: string) => {
  const blockMonitor = (blockMonitorParams: BlockMonitorParams) =>
    fetcher.patch<BlockMonitorParams, never>(
      `${SENSE_DEVICES_BASE_PATH}/gateways/${gatewaySerialNumber}/association-list`,
      blockMonitorParams,
    );

  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<UpdateAssociationListResponse>,
    AxiosError,
    BlockMonitorParams
  >(blockMonitor, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([associationListQueryKey]);
    },
  });
};

export const useGetGatewayUpdateStatus = <T>(options?: UseQueryOptions<T>) =>
  useQuery({
    queryKey: ['get-gateway-update-status'],
    queryFn: async () => fetcher.get<T>(`${SENSE_DEVICES_BASE_PATH}/gateways/update`),
    ...options,
  });

export const postGatewayUpdateStatus = () =>
  useMutation(async () => fetcher.post(`${SENSE_DEVICES_BASE_PATH}/gateways/update`, {}));
